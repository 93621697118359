import { fallbackLng, languages } from "@/lib/i18n/settings";
import Link from "next/link";
import React from "react";

type LocaleLinkProps = React.ComponentPropsWithoutRef<typeof Link> & {
  lng: string;
};

const LocaleLink: React.FC<LocaleLinkProps> = (props) => {
  const { lng, href, ...restProps } = props;
  let hrefWithLocale = href;

  if (languages.includes(lng)) hrefWithLocale = `/${lng}${href}`;

  if (lng === fallbackLng) hrefWithLocale = String(href);

  if (typeof href === "string" && href.startsWith("http"))
    hrefWithLocale = href;

  return <Link href={hrefWithLocale} {...restProps} />;
};

export default LocaleLink;
