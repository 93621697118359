"use client";

import LanguageSelector from "@/components/LanguageSelector";
import { useTranslation } from "@/lib/i18n";
import { fallbackLng } from "@/lib/i18n/settings";
import Image from "next/image";
import React, { Suspense } from "react";
import { Trans } from "react-i18next";
import LogoFile from "../../public/logo/talencat-logo.jpg";
import { LoginForm } from "../login/form";
import LocaleLink from "./LocaleLink";

const LoginSection: React.FC<{ lng?: string }> = ({ lng = fallbackLng }) => {
  const { t } = useTranslation({ lng, ns: "login" });

  return (
    <div>
      <div className="flex items-center gap-4 p-8">
        <div className="shrink-0">
          <Image src={LogoFile} alt="TalenCat Logo" height={40} width={40} />
        </div>
        <LanguageSelector className="w-40 border-l pl-4" hideLabel />
      </div>

      <div className="mx-auto flex flex-col items-center justify-center">
        <div className="max-w-[350px]">
          <Suspense>
            <LoginForm t={t} lng={lng} />
          </Suspense>

          <div className="mt-12 w-full text-center text-xs text-neutral-400">
            <Trans i18nKey="login.footer.tip" t={t}>
              <LocaleLink
                href={t("externalLink.legal.terms")}
                lng={lng}
                className="underline"
              />
              <LocaleLink
                href={t("externalLink.legal.privacy")}
                lng={lng}
                className="underline"
              />
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSection;
