"use client";

import { Button } from "@/components/ui/button";
import { authClient } from "@/lib/auth-client";
import GoogleLogoSVG from "@/public/svgs/brands/google.svg";
import EmailSignInForm from "../../components/EmailSignInForm";

export const LoginForm = ({ t, lng }: { t: any; lng?: string }) => {
  return (
    <div className="mt-20">
      <div className="mb-10 ">
        <p className="text-2xl font-semibold">{t("welcome.tip")}</p>
        <p className="mt-1 text-stone-500">{t("logIn.tip")}</p>
      </div>

      <div className="flex flex-col gap-4">
        <Button
          variant={"outline"}
          className="w-full"
          onClick={async () => {
            await authClient.signIn.social({
              provider: "google",
            });
          }}
        >
          <GoogleLogoSVG className="text-base" />
          {t("continueWithGoogle")}
        </Button>
      </div>

      <div className="relative my-6 text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t after:border-border">
        <span className="relative z-10 bg-background px-2 text-muted-foreground">
          {t("or")}
        </span>
      </div>

      <EmailSignInForm t={t} lng={lng} />
    </div>
  );
};
